<template>
  <div>
    <Loading v-show="loading" />
    <Rule v-if="!loading" :rules="rules" :urlAdd="'/api/v1/support/company-rule/create'"
      :urlRemove="`/api/v1/support/company-rule/remove`" :disabled="false" :companyId="companyId" />
  </div>
</template>

<script>
import Rule from "./Rule.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading";

import { mapActions } from "vuex";

export default {
  name: "RuleCompany",
  components: { Toggle, Rule, Loading },
  data() {
    return {
      companyId: this.$route.params.id,
      urlGetAll: "/api/v1/support/company-rule/get-all",
      rules: [
        {
          name: "Adm",
          nameBr: "Adm",
          ruleId: "e0c58623-e74f-4c7b-ad9f-b20c21bae7d5",
          hasRule: false,
        },
        {
          name: "Signature",
          nameBr: "Assinatura Digital",
          ruleId: "6e3f2fa1-cc8e-4541-9cf9-2201d7ecf56d",
          hasRule: false,
        },
        {
          name: "Billet",
          nameBr: "Boleto Bancário",
          ruleId: "1e50120f-19d4-411c-a819-687dfb355560",
          hasRule: false,
        },
        {
          name: "CRM",
          nameBr: "CRM",
          ruleId: "30c2b0df-eb47-4dcf-a8b6-643cd0166318",
          hasRule: false,
        },
        /*  {
            name: "Cashier",
            nameBr: "Caixa",
            ruleId: "8078bfde-9b19-43fa-aae3-79859b9c082a",
            hasRule: false,
          },*/
        {
          name: "Stock",
          nameBr: "Estoque",
          ruleId: "c429477f-0f93-419b-9023-c5832b90624f",
          hasRule: false,
        },
        {
          name: "Billing",
          nameBr: "Faturamento",
          ruleId: "5cf2112a-d81d-4c31-b131-cb0e9fad32e0",
          hasRule: false,
        },
        {
          name: "Finance",
          nameBr: "Financeiro",
          ruleId: "2e0b4465-57b4-405c-9b1d-cf7730cde5d0",
          hasRule: false,
        },
        {
          name: "Supplier",
          nameBr: "Fornecedor",
          ruleId: "a1329957-f9ee-4304-8954-be4ee24599ad",
          hasRule: false,
        },
        {
          name: "Operational",
          nameBr: "Operacional",
          ruleId: "11b75c86-55ec-45e5-95fa-74318db46caa",
          hasRule: false,
        },
        {
          name: "Rental",
          nameBr: "Locação",
          ruleId: "e847c004-9a13-4ada-80ea-34f3a876cec3",
          hasRule: false,
        },
        /*   {
             name: "PDV",
             nameBr: "PDV",
             ruleId: "ee8acf41-12d9-4702-a5c2-ab42b379b8fd",
             hasRule: false,
           },*/
        {
          name: "Maintenance",
          nameBr: "Manutenção",
          ruleId: "3078a3cc-f099-425e-b0b8-dcb70ccc131d",
          hasRule: false,
        },
        {
          name: "TaxDocument",
          nameBr: "Nota Fiscal",
          ruleId: "f12d6f30-dbb5-427c-961c-712235a3aa37",
          hasRule: false,
        },
        {
          name: "Planner",
          nameBr: "Planner",
          ruleId: "5740acc6-f635-421f-a403-37ead90fceec",
          hasRule: false,
        },
        {
          name: "HumanResources",
          nameBr: "Recursos Humanos",
          ruleId: "3ebbef5c-faf4-4133-9623-d938f3eba2e2",
          hasRule: false,
        },
      ],
      loading: true,
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll() {
      let params = { url: this.urlGetAll, obj: { companyId: this.companyId } };
      this.getApi(params).then((response) => {
        let self = this;
        response.content.content.forEach(function (rule) {
          self.hasRule(rule);
        });
        this.loading = false;
      });
    },
    hasRule(rule) {
      this.rules.forEach(function (value) {
        if (value.ruleId === rule.ruleId) value.hasRule = true;
      });
    },
  },
};
</script>
